import axios from '@/libs/axios'

class DataService {
  getAll(params) {
    return axios.get("/medicalReps?"+params);
  }
  get(id) {
    return axios.get(`/medicalReps/${id}`);
  }
  create(data) {
    return axios.post("/medicalReps", data);
  }
  update(id, data) {
    return axios.put(`/medicalReps/${id}`, data);
  }
}
export default new DataService();